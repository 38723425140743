import React, {useState, useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link} from 'gatsby-plugin-react-i18next';
import slugify from 'slugify';
import HTMLFlipBook from 'react-pageflip';
import {pdfjs, Document, Page as ReactPdfPage} from 'react-pdf';

import {Card, Layout, Post, Seo, Share} from '@app/components';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '@app/styles/templates/blog-post.scss';
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../firebase';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type BlogPostTemplatePropTypes = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        author: string;
        categories: [];
        tags: [];
        date: string;
        cover: {
          childImageSharp: {
            fluid: [];
          };
        };
        pdf?: {
          publicURL: string;
        };
      };
      excerpt: string;
      html: string;
    };
    allMarkdownRemark: {
      nodes: {
        excerpt: string;
        frontmatter: {
          title: string;
          author: string;
          date: string;
          cover: any;
          categories: [];
        };
      }[];
    };
  };
};

const BlogPostTemplate: React.FC<BlogPostTemplatePropTypes> = ({data}) => {
  const post = data.markdownRemark;
  const lastPosts = data.allMarkdownRemark.nodes;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [userAgent, setUserAgent] = useState('');
  const [width, setWidth] = useState(784);
  const [height, setHeight] = useState(1200);

  const Page = React.forwardRef(({pageNumber}, ref) => {
    return (
      <div ref={ref}>
        <ReactPdfPage pageNumber={pageNumber} width={width} />
      </div>
    );
  });

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  const detectUserAgent = () => {
    const isMobile =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i);

    setUserAgent('isMobile');

    setWidth(isMobile && window ? window.innerWidth - 32 : 784);
    setHeight(isMobile ? window.innerWidth + 133 : 1100);
  };

  useEffect(() => {
    detectUserAgent();
  }, []);

  return (
    <Layout>
      <Seo title={post.frontmatter.title} description={post.excerpt} />

      <div className="container mx-auto px-4 py-20 xl:max-w-container-xl lg:px-0">
        <div className="grid lg:grid-cols-12 gap-12">
          <div className="lg:col-span-8">
            <Post.Cover
              title={
                post.frontmatter.title.includes(':')
                  ? post.frontmatter.title.split(':')[1]
                  : post.frontmatter.title
              }
              author={post.frontmatter.author}
              categories={post.frontmatter.categories}
              date={post.frontmatter.date}
              cover={post.frontmatter.cover?.childImageSharp.fluid}
            />
            <Post.Content content={post.html} />

            <div
              className={
                post.frontmatter.title.includes(':')
                  ? 'mt-12 mb-20 text-xl'
                  : 'hidden'
              }
            >
              <b>
                {post.frontmatter.title.includes(':')
                  ? post.frontmatter.title.split(':')[0]
                  : ''}
              </b>
            </div>
            <div className="mt-12 mb-20">
              <Share title={post.frontmatter.title} />
            </div>
            <hr />
            {post.frontmatter.tags && (
              <div className="tag">
                {post.frontmatter.tags.map((tag) => (
                  <Link
                    to={`/tag/${slugify(tag, {
                      lower: true,
                      strict: true,
                    })}`}
                    rel="tag"
                    className="tag__item"
                    key={tag}
                  >
                    {tag}
                  </Link>
                ))}
              </div>
            )}

            <div className="pattern-large mt-10" />
          </div>
        </div>
      </div>

      {lastPosts && (
        <div className="container mx-auto px-4 py-20 xl:max-w-container-xl lg:px-0">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-20">
            {lastPosts.map((lastPost) => (
              <Card
                title={lastPost.frontmatter.title}
                description={lastPost.excerpt}
                author={lastPost.frontmatter.author}
                date={lastPost.frontmatter.date}
                cover={lastPost.frontmatter.cover?.childImageSharp.fluid}
                key={lastPost.frontmatter.title}
              />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $title: String!, $language: String!) {
    markdownRemark(id: {eq: $id}) {
      excerpt(pruneLength: 290)
      html
      frontmatter {
        title
        author
        categories
        tags
        date(formatString: "MMMM D, YYYY", locale: $language)
        cover {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        id: {ne: $id}
        frontmatter: {categories: {eq: $title}, language: {eq: $language}}
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 140)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
